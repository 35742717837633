import React, { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import AOS from 'aos';

import FeaturesImage from "../assets/images/features.png"
import { FeatureArrow, Music, SmartLyrics, Sync } from "../utils/svg";

const Features = () => {

    useEffect(() => {
        AOS.init({
            duration: 1200,
        });
    }, []);

    const partnership = [
        { id: 1, title: "ASK", text: "Aurora Sky" },
        { id: 2, title: "MGV", text: "Midnight Groove" },
        { id: 3, title: "NVB", text: "Nova Beats" },
    ]
    return (
        <div className="about-us theme-row container mt-10 mb-5" id="features">
            <Row className="align-items-center">
                <Col md={12} lg={6} className="image-col">
                    <div className="image-content image-content-features" data-aos="flip-left">
                        <img src={FeaturesImage} alt="add-image" className="object-fit-contain image-fluid" />
                    </div>
                </Col>
                <Col md={12} lg={6} className="content-col">
                    <div className="text-content d-flex flex-column mt-3" data-aos="fade-left">
                        <h1 className="extra-bold">Features <FeatureArrow /></h1>
                        <div className="partnership-btn d-flex">
                            <div className="partner-btn d-flex flex-column">
                                <Music />
                                <span className="w-75">High Definition Audio</span>
                            </div>
                            <div className="partner-btn d-flex flex-column">
                                <Sync />
                                <span className="w-75">Cross Platform Sync</span>
                            </div>
                            <div className="partner-btn d-flex flex-column">
                                <SmartLyrics />
                                <span className="w-75">Smart Lyrics Integration</span>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Features;
