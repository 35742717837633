import React from "react";
import Fuelling from "../components/Fuelling";
import AboutUs from "../components/Aboutus";
import Variety from "../components/Variety";
import Features from "../components/Features";
import HomeSlider from "../components/AppSlider";
import EnjoyBest from "../components/EnjoyBest";

const Home = () => {
    return (
        <div className="home mt-5 mb-5">
            <Fuelling />
            <AboutUs />
            <Variety />
            <Features />
            <HomeSlider />
            <EnjoyBest />
        </div>
    )
}

export default Home;