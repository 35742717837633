import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import logo from '../assets/images/logo.svg';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';


const TopNavBar = () => {
    const [active, setActive] = useState(1)
    const [scrollToSection, setScrollToSection] = useState(null);

    let location = window.location;
    let pathname = location.pathname;

    const navigate = useNavigate();
    const activeLink = (route) => {
        return pathname === route ? true : false;
    };


    useEffect(() => {
        if (scrollToSection && location.pathname === '/') {
            const element = document.getElementById(scrollToSection);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
            setScrollToSection(null);
        }
    }, [location, scrollToSection]);

    const handleClick = (path) => {
        if (path.startsWith('#')) {
            console.log("first");

            const sectionId = path.slice(1);
            if (location.pathname !== '/') {
                console.log("inner first");
                setScrollToSection(sectionId);
                navigate('/');
            } else {
                console.log("inner first false");
                const element = document.getElementById(sectionId);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
        } else {
            navigate(path);
        }
    };

    const Pages = [
        { id: 1, title: 'Home', path: '/' },
        { id: 2, title: 'Features', path: '#features' },
        { id: 3, title: 'About Us', path: '#about-us' },
        { id: 4, title: 'Terms', path: '/terms-and-condition' },
        { id: 5, title: 'Privacy', path: '/privacy-policy' },
    ]
    return (
        <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand href="/"><img className='logo' src={logo} alt={"logo"} loading='lazy' /></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto">
                        {Pages.map((dt) => (
                            <span
                                key={dt.id}
                                onClick={() => handleClick(dt.path)}
                                style={{ cursor: 'pointer', marginRight: '10px' }}
                                className={window.location.pathname === dt.path ? "active" : ""}
                            >
                                {dt.title}
                            </span>
                        ))}
                    </Nav>
                    <Button className='btn-contact-us' onClick={() => navigate("/contact-us")}>Contact Us</Button>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default TopNavBar;