import React, { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import AOS from 'aos';

import varietyImage from "../assets/images/variety.png"
import Range from "../assets/images/range.png"
import PlayButton from "../assets/images/button.png"

import u1 from "../assets/images/u-1.png"
import u2 from "../assets/images/u-2.png"
import u3 from "../assets/images/u-3.png"

const Variety = () => {

    useEffect(() => {
        AOS.init({
            duration: 1200,
        });
    }, []);

    const userImages = [
        { id: 1, image: u1 },
        { id: 2, image: u2 },
        { id: 3, image: u3 },
    ]
    return (
        <div className="variety theme-row container mt-10 mb-5">
            <Row>
                <Col md={12} lg={6} className="content-col">
                    <div className="text-content d-flex flex-column mt-3" data-aos="fade-left">
                        <h1 className="extra-bold">Variety Music Category</h1>
                        <p>Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam. Maecenas ligula massa, varius a, semper congue, euismod non, mi. Proin porttitor, orci nec nonummy molestie, enim est eleifend mi, non fermentum diam nisl sit amet erat.</p>
                        <Button className="theme-btn">Discover More</Button>
                        <div className="users-love mt-4 d-flex justify-content-between">
                            <div className="image-sec d-flex flex-column">
                                <div className="images">
                                    {userImages?.map((dt) => (
                                        <img key={dt?.id} src={dt?.image} alt="user-image" className={`image-fluid object-fit-contain rounded-circle image-${dt?.id}`} />
                                    ))}
                                </div>
                                <div className="text">
                                    Users love this genre!
                                </div>
                            </div>
                            <div className="now-playing-sec d-flex">
                                <div className="playing d-flex flex-column">
                                    <div className="text mb-2">
                                        <p className="mb-2">Now Playing</p>
                                        <span>First Glance</span>
                                    </div>
                                    <img src={Range} alt="range" className="image-fluid object-fit-contain" />
                                </div>
                                <img src={PlayButton} alt="icon" className="play-btn image-fluid object-fit-contain" />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={12} lg={6} className="image-col image-verity">
                    <div className="image-content" data-aos="flip-left">
                        <img src={varietyImage} alt="add-image" className="object-fit-contain image-fluid" />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Variety;
