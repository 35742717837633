import React from "react"

import play from "../assets/images/play.png"
import AppleStore from "../assets/images/apple.png"
import GoogleStore from "../assets/images/google.png"
import f1 from "../assets/images/f-1.png"
import f2 from "../assets/images/f-2.png"
import f3 from "../assets/images/f-3.png"
import f4 from "../assets/images/f-4.png"

const Fuelling = () => {
    const fuellingImage = [
        { id: 1, image: f1 },
        { id: 1, image: f2 },
        { id: 1, image: f3 },
        { id: 1, image: f4 }
    ]
    return (
        <div className="container fuelling-section d-flex flex-column justify-content-center align-items-center">
            <div className="content text-center">
                <h1>Fuelling Your Passion Igniting <span className="extra-bold">Your Potential!</span></h1>
                <p>Ignite your passion, fuel your curiosity, and unleash your potential with our diverse array of captivating podcasts.</p>
            </div>
            <div className="fuelling-images d-flex mt-5">
                {fuellingImage?.map((dt) => (
                    <img key={dt?.id} src={dt?.image} alt={`fuelling-imag-${dt?.id}`} className="object-fit-contain image-fluid" />
                ))
                }
            </div>
            <div className="play-image">
                <img src={play} alt="play" className="object-fit-contain image-fluid" />
            </div>
            <div className="store-images">
                <div className="store_image d-flex mt-5">
                    <img className="cursor" loading="lazy" src={AppleStore} alt="appleStore" />
                    <img className="cursor" loading="lazy" src={GoogleStore} alt="googleStore" />
                </div>
                <p className="mt-3">Application is free to download on iOS.</p>
            </div>
        </div>
    )
}
export default Fuelling