import React, { useEffect } from 'react';
import AOS from 'aos';

import enjoyBest from "../assets/images/enjoy.png";
import AppleStore from "../assets/images/apple.png";
import GoogleStore from "../assets/images/google.png";
const EnjoyBest = () => {
    useEffect(() => {
        AOS.init({
            duration: 1200,
        });
    }, []);

    return (
        <div className='enjoy-best container mt-5 mb-10'>
            <div className='enjoy-content d-flex justify-content-center align-items-center text-center'>
                <div className='image-content'>
                    <img src={enjoyBest} alt='enjoy-image' className='object-fit-conatin image-fluid' />
                    <div className='content'>
                        <p className="extra-bold" data-aos="fade-up">Enjoy Best Experience on Our App</p>
                        <div className="store_image d-flex mt-5 d-flex justify-content-center align-items-center">
                            <img className="cursor" loading="lazy" src={AppleStore} alt="appleStore" />
                            <img className="cursor" loading="lazy" src={GoogleStore} alt="googleStore" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EnjoyBest;