import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import HomeLayOut from "../layouts/HomeLayOut";
import TermsAndConditions from "../pages/Terms";
import Privacy from "../pages/Privacy";
import ContactUs from "../components/ContactUs";

const Navigation = () => {
    return (
        <>
            <Routes>
                <Route path={"/"} element={<HomeLayOut children={<Home />} />}></Route>
                <Route path={"/terms-and-condition"} element={<HomeLayOut children={<TermsAndConditions />} />}></Route>
                <Route path={"/privacy-policy"} element={<HomeLayOut children={<Privacy />} />}></Route>
                <Route path={"/contact-us"} element={<HomeLayOut children={<ContactUs />} />}></Route>
            </Routes>
        </>
    )
}

export default Navigation