function FeatureArrow() {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.7667 45.5L10.5 42.2333L37.5667 15.1667H22.1667V10.5H45.5V33.8333H40.8333V18.4333L13.7667 45.5Z" fill="white" />
        </svg>
    )
}
function Music() {
    return (
        <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 42.5H10C8.9 42.5 7.95833 42.1083 7.175 41.325C6.39167 40.5417 6 39.6 6 38.5V24.5C6 22 6.475 19.6583 7.425 17.475C8.375 15.2917 9.65833 13.3917 11.275 11.775C12.8917 10.1583 14.7917 8.875 16.975 7.925C19.1583 6.975 21.5 6.5 24 6.5C26.5 6.5 28.8417 6.975 31.025 7.925C33.2083 8.875 35.1083 10.1583 36.725 11.775C38.3417 13.3917 39.625 15.2917 40.575 17.475C41.525 19.6583 42 22 42 24.5V38.5C42 39.6 41.6083 40.5417 40.825 41.325C40.0417 42.1083 39.1 42.5 38 42.5H30V26.5H38V24.5C38 20.6 36.6417 17.2917 33.925 14.575C31.2083 11.8583 27.9 10.5 24 10.5C20.1 10.5 16.7917 11.8583 14.075 14.575C11.3583 17.2917 10 20.6 10 24.5V26.5H18V42.5Z" fill="white" />
        </svg>
    )
}

function Sync() {
    return (
        <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28 40.5V36.5H33.2L26.85 30.15L29.7 27.3L36 33.6V28.5H40V40.5H28ZM10.8 40.5L8 37.7L33.2 12.5H28V8.5H40V20.5H36V15.3L10.8 40.5ZM18.35 21.65L8 11.3L10.8 8.5L21.15 18.85L18.35 21.65Z" fill="white" />
        </svg>
    )
}

function SmartLyrics() {
    return (
        <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 29.5H18V25.5H10V29.5ZM36 25.5C34.3333 25.5 32.9167 24.9167 31.75 23.75C30.5833 22.5833 30 21.1667 30 19.5C30 17.8333 30.5833 16.4167 31.75 15.25C32.9167 14.0833 34.3333 13.5 36 13.5C36.3667 13.5 36.7083 13.5333 37.025 13.6C37.3417 13.6667 37.6667 13.75 38 13.85V3.5H46V7.5H42V19.5C42 21.1667 41.4167 22.5833 40.25 23.75C39.0833 24.9167 37.6667 25.5 36 25.5ZM10 23.5H24V19.5H10V23.5ZM10 17.5H24V13.5H10V17.5ZM10 37.5L2 45.5V9.5C2 8.4 2.39167 7.45833 3.175 6.675C3.95833 5.89167 4.9 5.5 6 5.5H28C29.1 5.5 30.0417 5.89167 30.825 6.675C31.6083 7.45833 32 8.4 32 9.5V10.35C30.1667 11.15 28.7083 12.375 27.625 14.025C26.5417 15.675 26 17.5 26 19.5C26 21.5 26.5417 23.325 27.625 24.975C28.7083 26.625 30.1667 27.85 32 28.65V33.5C32 34.6 31.6083 35.5417 30.825 36.325C30.0417 37.1083 29.1 37.5 28 37.5H10Z" fill="white" />
        </svg>
    )
}
export { FeatureArrow, Music, Sync, SmartLyrics }