import React from "react";
import TopNavBar from "../components/TopNavbar";
import ThemeFooter from "../components/ThemeFooter";

const HomeLayOut = ({ children }) => {
    return (
        <div className="theme-layout">
            <TopNavBar />
            <div className="children">
                {children}
            </div>
            <ThemeFooter />
        </div>
    )
}

export default HomeLayOut;