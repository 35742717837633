import axios from "axios";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";

const ContactUs = () => {
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        message: "",
    });

    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();

            const result = await axios.post(
                "http://api.rolidex.trangotechdevs.com:3019/api/user/contact-us",
                {
                    first_name: formData?.first_name,
                    last_name: formData?.last_name,
                    email: formData?.email,
                    message: formData?.message,
                }
            );

            const { code, message } = result.data;
            if (code == 200) {
                toast.success(message);

                setFormData({
                    first_name: "",
                    last_name: "",
                    email: "",
                    message: "",
                });
            }
        } catch (e) {
            console.log(e.message);
            toast.error(e.message);
        }
    };

    return (
        <div className="contact-us container mt-5">
            <h1 className="theme-h1 extra-bold">Contact Us</h1>
            <div className="mt-3 mb-5 form">
                <form onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={12} md={6}>
                            <Row>
                                <Col xs={12} md={6} className="mb-4">
                                    <label className="label-form">First Name</label>
                                    <br />
                                    <input
                                        type="text"
                                        name="first_name"
                                        className="form-control border"
                                        autoComplete="off"
                                        placeholder="Write First Name"
                                        required
                                        onChange={handleChange}
                                        value={formData.first_name}
                                    />
                                </Col>
                                <Col xs={12} md={6} className="mb-4">
                                    <label className="label-form">Last Name</label>
                                    <br />
                                    <input
                                        type="text"
                                        name="last_name"
                                        autoComplete="off"
                                        className="form-control border "
                                        placeholder="Write Last Name"
                                        required
                                        onChange={handleChange}
                                        value={formData.last_name}
                                    />
                                </Col>
                                <Col xs={12} className="mb-4">
                                    <label className="label-form">Email Address</label>
                                    <br />
                                    <input
                                        type="email"
                                        name="email"
                                        autoComplete="off"
                                        className="form-control border "
                                        placeholder="Write Email Address"
                                        required
                                        onChange={handleChange}
                                        value={formData.email}
                                    />
                                </Col>
                                <Col xs={12} className="mb-4">
                                    <label className="label-form">Message</label>
                                    <br />
                                    <textarea
                                        name="message"
                                        className="form-control border"
                                        placeholder="Write a message"
                                        required
                                        onChange={handleChange}
                                        value={formData.message}
                                    ></textarea>
                                </Col>
                                <Col xs={12} md={4} className="mb-4">
                                    <button type="submit" className="form-control theme-btn">
                                        Submit
                                    </button>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={6}></Col>
                    </Row>
                </form>
            </div>
        </div>
    );
};

export default ContactUs;
