import React, { useEffect, useState } from "react";

import logo from "../assets/images/logo.svg";
import { BsSend } from "react-icons/bs";
import facebook from "../assets/images/facebook.png";
import insta from "../assets/images/insta.png";
import Tweeter from "../assets/images/tweeter.png";
import { Link, useLocation, useNavigate } from "react-router-dom";

const ThemeFooter = () => {
    const [scrollToSection, setScrollToSection] = useState(null);
    let location = window.location;
    const navigate = useNavigate();

    const Pages = [
        { id: 1, title: 'Home', path: '/' },
        { id: 2, title: 'Features', path: '#features' },
        { id: 3, title: 'About Us', path: '#about-us' },
    ]


    useEffect(() => {
        if (scrollToSection && location.pathname === '/') {
            const element = document.getElementById(scrollToSection);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
            // setScrollToSection(null); // Reset after scrolling
        }
    }, [location, scrollToSection]);


    useEffect(() => {
        let path = location.pathname.split("");
        console.log(path, 'immer footer');

        if (path?.length > 1) {
            window.scrollTo(0, 0);
        }
    }, [location?.pathname]);
    const handleClick = (path) => {
        if (path.startsWith('#')) {
            const sectionId = path.slice(1);
            if (location.pathname !== '/') {
                setScrollToSection(sectionId);  // Set section to scroll after navigation
                navigate('/');                  // Navigate to home page
            } else {
                const element = document.getElementById(sectionId);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
        } else {
            // Navigate to external path
            navigate(path);
        }
    };

    return (
        <>
            <div className="theme-footer">
                <div className="top-content container d-flex justify-content-between">
                    <div className="content-footer d-flex justify-content-between flex-column">
                        <div className="brand">
                            <img className='logo object-fit-contain image-fluid' src={logo} alt={"logo"} loading='lazy' />
                            <p className="mt-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.</p>
                        </div>
                        <p className="text d-lg-block d-none">Cristiano. © 2024 All rights reserved. Designed & Powered by TrangoTech</p>
                    </div>
                    <div className="links d-flex flex-column">
                        <h2>Links</h2>
                        <ul className="mt-3">
                            {Pages.map((dt) => (
                                <li key={dt.id}
                                    onClick={() => handleClick(dt.path)}
                                    style={{ cursor: 'pointer', marginRight: '10px' }}
                                >{dt.title}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="connect-us d-flex justify-content-between flex-column">
                        <h2>Connect with Us</h2>
                        <div className="connect mt-5 mb-3">
                            <input type="text" placeholder="Please enter your email" />
                            <BsSend color="#FCFCFC" size={22} />
                        </div>
                        <h2 className="mb-3">Our Social Links</h2>
                        <div className="social-media d-flex">
                            <Link>
                                <img src={facebook} alt="icon-social" className="object-fit-contain image-fluid" loading="lazy" />
                            </Link>
                            <Link>
                                <img src={Tweeter} alt="icon-social" className="object-fit-contain image-fluid" loading="lazy" />
                            </Link>
                            <Link>
                                <img src={insta} alt="icon-social" className="object-fit-contain image-fluid" loading="lazy" />
                            </Link>
                        </div>

                        <div className="d-flex links mt-5">
                            <Link to="/terms-and-condition">Terms of Use</Link>
                            <Link to="/privacy-policy">Privacy Policy</Link>
                        </div>

                    </div>
                </div>
                <div className="bottom-content container mt-5 d-lg-none ">
                    <p className="text">Cristiano. © 2024 All rights reserved. Designed & Powered by TrangoTech</p>
                </div>
                <div className="light"></div>
            </div>
        </>
    )
}

export default ThemeFooter;