import React, { useEffect } from "react";
import AOS from 'aos';

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import one from "../assets/images/1.png";
import two from "../assets/images/2.png";
import three from "../assets/images/3.png";

const HomeSlider = () => {
    useEffect(() => {
        AOS.init({
            duration: 1200,
        });
    }, []);

    const data = [
        { id: 1, image: one },
        { id: 2, image: two },
        { id: 3, image: three },
        { id: 4, image: one },
        { id: 5, image: two },
        { id: 6, image: three },
        { id: 7, image: one },
        { id: 8, image: two },
        { id: 9, image: three },
    ];
    return (
        <div className="app-slider">
            <div className="home-slider-componenet mt-5 mb-5">
                <div className="top-text mb-3">
                    <h1 className="extra-bold">App Screen</h1>
                </div>
                <div className="slider-component">
                    <Swiper
                        effect={"coverflow"}
                        grabCursor={true}
                        centeredSlides={true}
                        loop={true}
                        slidesPerView={"auto"}
                        coverflowEffect={{
                            rotate: 0,
                            stretch: 0,
                            depth: 100,
                            modifier: 2.5,
                        }}
                        pagination={{ el: ".swiper-pagination", clickable: true }}
                        navigation={{
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                            clickable: true,
                        }}
                        modules={[EffectCoverflow, Navigation]}
                        className="swiper_container"
                    >
                        {data?.map((dt) => (
                            <>
                                <SwiperSlide key={dt?.id} >
                                    <img loading="lazy" src={dt?.image} alt="slide_image" data-aos="flip-left" />
                                </SwiperSlide>
                            </>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default HomeSlider;
