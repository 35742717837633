import React, { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import AOS from 'aos';

import AaboutusImage from "../assets/images/aboutus.png"
const AboutUs = () => {

    useEffect(() => {
        AOS.init({
            duration: 1200,
        });
    }, []);

    const partnership = [
        { id: 1, title: "ASK", text: "Aurora Sky" },
        { id: 2, title: "MGV", text: "Midnight Groove" },
        { id: 3, title: "NVB", text: "Nova Beats" },
    ]
    return (
        <div className="about-us theme-row container mt-10 mb-5" id="about-us">
            <Row>
                <Col md={12} lg={6} className="image-col">
                    <div className="image-content" data-aos="flip-left">
                        <img src={AaboutusImage} alt="add-image" className="object-fit-contain image-fluid" />
                    </div>
                </Col>
                <Col md={12} lg={6} className="content-col">
                    <div className="text-content d-flex flex-column mt-3" data-aos="fade-left">
                        <h1 className="extra-bold">About Us</h1>
                        <p>Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam. Maecenas ligula massa, varius a, semper congue, euismod non, mi. Proin porttitor, orci nec nonummy molestie, enim est eleifend mi, non fermentum diam nisl sit amet erat.</p>
                        <Button className="theme-btn">Learn More</Button>
                        <h2>In Partnership with</h2>
                        <div className="partnership-btn d-flex">
                            {partnership?.map((dt) => (
                                <div key={dt?.id} className="partner-btn d-flex flex-column">
                                    <p>{dt?.title}</p>
                                    <span>{dt?.text}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default AboutUs;
